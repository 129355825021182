import client from "@/utils/api/client";
import useFetch from "@/hooks/useFetch";
import useHasReadNotification from "@/features/notification/hooks/useHasReadNotification";
import Link from "next/link";
import { FC, PropsWithChildren, useEffect, useMemo } from "react";
import { ArrowContainer, Popover } from "react-tiny-popover";
import BorderList from "@/components/BorderList";
import NotificationItem from "@/features/notification/components/NotificationItem";

type Props = {
  isOpen: boolean;
  close: VoidFunction;
};

const NotificationPopover: FC<PropsWithChildren<Props>> = ({
  isOpen,
  close,
  children,
}): JSX.Element => {
  const now = useMemo(() => new Date(), []);
  const { data: notifications } = useFetch(
    isOpen ? "/latest_notifications" : null,
    async () => {
      const { data } = await client.notificationsGet({ limit: 3 });

      return data.results;
    },
    undefined,
    { waitForAuth: true },
  );

  const { hasRead } = useHasReadNotification();
  useEffect(() => {
    if (isOpen) {
      hasRead();
    }
  }, [isOpen]);

  return (
    <Popover
      isOpen={isOpen}
      positions={["bottom"]}
      containerClassName={"z-modal max-w-[320px]"}
      onClickOutside={() => close()}
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor={"white"}
          arrowSize={10}
          className="popover-arrow-container  "
          arrowClassName="popover-arrow"
        >
          <div className="bg-white rounded-sm p-12 shadow-default">
            <BorderList borderStyle={"solid"}>
              {notifications &&
                notifications.map((notification) => (
                  <li key={notification.id}>
                    <NotificationItem
                      notification={notification}
                      nowDate={now}
                    />
                  </li>
                ))}

              <li>
                <Link
                  href="/notifications"
                  className="block pt-12 text-link font-bold text-center text-xs"
                >
                  すべての通知
                </Link>
              </li>
            </BorderList>
          </div>
        </ArrowContainer>
      )}
      padding={10}
    >
      <div className="relative">{children}</div>
    </Popover>
  );
};
export default NotificationPopover;
