import { RootState } from "@/stores/rootReducer";
import useDisclosure from "@/hooks/useDisclosure";
import dynamic from "next/dynamic";
import Link from "next/link";
import { useRouter } from "next/router";
import { FC, useCallback, useState } from "react";
import { useSelector } from "react-redux";
import Logo from "../assets/imgs/svg/logo-default.svg";
import BasicButton, { BasicButtonStyle } from "@/components/BasicButton";
import HeaderNavigationItem from "@/components/HeaderNavigationItem";
import RenderIfAuth from "@/components/RenderIfAuth";
import RenderIfNotAuth from "@/components/RenderIfNotAuth";
import SearchBar from "@/components/SearchBar";
import UserIcon from "@/features/user/components/UserIcon";
import { AvatarSize } from "@/components/Avatar";
import { theme } from "tailwind.config";
import NotificationPopover from "@/features/notification/components/NotificationPopover";
import useCart from "@/hooks/useCart";
import IconNotification from "../assets/imgs/svg/icon_notification.svg";
import IconCart from "../assets/imgs/svg/icon_cart.svg";
import Badge from "@/components/Badge";
import ActiveIconWrapper from "@/components/ActiveIconWrapper";
import { RedirectReason } from "@/domain/values/RedirectReason";
import useModalLimitFunction from "@/features/user/hooks/useModalLimitFunction";
import useUserStatus from "@/features/user/hooks/useUserStatus";
import { useSignInRequiredAction } from "@/features/user_authentication";
import Button from "@/components/Button";

const DynamicModalSearch = dynamic(() => import("./ModalSearch"));
const DynamicDialogJoinTeam = dynamic(
  () => import("@/features/team/components/DialogJoinTeam"),
);
const DynamicAccountNavigation = dynamic(
  () => import("@/components/TheAccountNavigation"),
);

const TheHeader: FC = (): JSX.Element => {
  const { user } = useSelector((state: RootState) => state.auth);
  const router = useRouter();
  const { hasTeam, isDesigner } = useUserStatus();

  const { open: openModalLimitFunction } = useModalLimitFunction();

  const [displayNavigation, setDisplayNavigation] = useState(false);

  const { isOpen, openHandler, closeHandler } = useDisclosure();
  const { quantity } = useCart();

  const {
    isOpen: isDialogJoinTeamOpen,
    openHandler: openDialogJoinTeam,
    closeHandler: closeDialogJoinTeam,
  } = useDisclosure();

  const { executeAction: clickPostProjectBtnHandler, SignInModalComponent } =
    useSignInRequiredAction({
      reason: RedirectReason.CreateProject,
      action: () => {
        if (!isDesigner) {
          openModalLimitFunction();

          return;
        }

        if (!hasTeam) {
          openDialogJoinTeam();

          return;
        }

        router.push(`/projects/new`);
      },
    });

  const clickCartBtnHandler = useCallback(() => {
    if (!isDesigner) {
      openModalLimitFunction();

      return;
    }

    router.push("/cart");
  }, [router, openModalLimitFunction, isDesigner]);

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  return (
    <header className="TheHeader">
      <div className="laptop:pt-12 laptop:px-12">
        <div className="laptop:space-y-6">
          <div className="flex items-center justify-between laptop:justify-start laptop:px-0 laptop:pt-0 laptop:space-x-12 pt-12 px-12">
            <div className="leading-none flex items-center space-x-12 laptop:space-x-24">
              <Link
                href="/"
                prefetch={false}
                className="TheHeader__logo SVGWrapper"
              >
                <Logo />
              </Link>
            </div>

            <div className="TheHeader__search hidden laptop:flex">
              <div className="flex-1 text-2xs laptop:text-sm">
                <SearchBar
                  placeholder="建物や家具の名前で検索してみよう。"
                  backgroundColor={theme.colors.gray[100]}
                />
              </div>
            </div>

            <nav className="TheHeader__nav leading-none space-x-12 laptop:space-x-0">
              <div className="laptop:hidden">
                <button onClick={openHandler}>
                  <i className="icon-Search text-xl"></i>
                </button>
              </div>
              <RenderIfNotAuth>
                <ul className="space-x-16 flex items-center">
                  <li>
                    <button
                      type="button"
                      onClick={clickCartBtnHandler}
                      aria-label="カートに移動する"
                    >
                      <Badge badgeContent={quantity}>
                        <IconCart
                          width={24}
                          height={24}
                          fill={theme.colors.gray[950]}
                        />
                      </Badge>
                    </button>
                  </li>
                  <li>
                    <Link href="/signin" prefetch={false}>
                      ログイン
                    </Link>
                  </li>
                  <li>
                    <Link href="/signup" prefetch={false}>
                      新規登録
                    </Link>
                  </li>

                  <li className="hidden laptop:block">
                    <BasicButton
                      style={BasicButtonStyle.UniqueAction}
                      label={"プロジェクト投稿"}
                      onClick={clickPostProjectBtnHandler}
                    />
                  </li>
                </ul>
              </RenderIfNotAuth>

              <RenderIfAuth>
                <ul className="flex items-center space-x-12">
                  <li>
                    <button
                      type="button"
                      onClick={clickCartBtnHandler}
                      aria-label="カートに移動する"
                    >
                      <Badge badgeContent={quantity}>
                        <IconCart
                          width={24}
                          height={24}
                          fill={theme.colors.gray[950]}
                        />
                      </Badge>
                    </button>
                  </li>
                  <li>
                    <NotificationPopover
                      isOpen={isPopoverOpen}
                      close={() => setIsPopoverOpen(false)}
                    >
                      <button onClick={() => setIsPopoverOpen((prev) => !prev)}>
                        <ActiveIconWrapper
                          active={user ? user.has_unread_notification : false}
                        >
                          <IconNotification
                            width={24}
                            height={24}
                            fill={theme.colors.gray[950]}
                          />
                        </ActiveIconWrapper>
                      </button>
                    </NotificationPopover>
                  </li>
                  <li
                    onClick={() => setDisplayNavigation(!displayNavigation)}
                    className="cursor-pointer"
                  >
                    <UserIcon
                      user={user}
                      size={AvatarSize.Normal}
                      hasLink={false}
                    />
                  </li>
                  <li className="hidden laptop:block">
                    <Button
                      color="secondary"
                      size="small"
                      onClick={clickPostProjectBtnHandler}
                    >
                      プロジェクト投稿
                    </Button>
                  </li>
                </ul>
                {displayNavigation && (
                  <div className="absolute top-[44px] right-[10px] laptop:top-[60px] laptop:right-[20px]">
                    <DynamicAccountNavigation />
                  </div>
                )}
              </RenderIfAuth>
            </nav>
          </div>

          <div className="flex items-center overflow-auto whitespace-nowrap text-xs space-x-16 laptop:justify-start laptop:space-x-24 laptop:text-sm font-bold laptop:px-24 px-12 py-16">
            <div className="flex whitespace-nowrap space-x-16 laptop:space-x-24">
              <HeaderNavigationItem href="/architectures">
                建築を探す
              </HeaderNavigationItem>
              <HeaderNavigationItem href="/building_materials">
                建材を探す
              </HeaderNavigationItem>
              <HeaderNavigationItem href="/interiors">
                家具を探す
              </HeaderNavigationItem>
              <HeaderNavigationItem href="/makers">
                メーカーを探す
              </HeaderNavigationItem>
            </div>

            <div className="flex whitespace-nowrap space-x-16 laptop:space-x-24 pl-12 laptop:pl-24 border-l border-primary">
              <HeaderNavigationItem href="/about">
                TECTUREとは？
              </HeaderNavigationItem>

              <HeaderNavigationItem href="/faq">
                よくあるご質問
              </HeaderNavigationItem>
            </div>
          </div>
        </div>

        {isOpen && <DynamicModalSearch closeHandler={closeHandler} />}
        <DynamicDialogJoinTeam
          isOpen={isDialogJoinTeamOpen}
          onClose={closeDialogJoinTeam}
        />
      </div>

      {SignInModalComponent}
    </header>
  );
};

export default TheHeader;
