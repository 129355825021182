import { RootState } from "@/stores/rootReducer";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import useSWR, { KeyedMutator } from "swr";

export interface FetchResponse<T> {
  data: T;
  mutate: KeyedMutator<T>;
  error?: Error;
  isLoading: boolean;
}

interface FetchOptions {
  waitForAuth?: boolean;
  mutateForAuth?: boolean;
  suspense?: boolean;
}

const useFetch = <T>(
  key: string,
  fetcher: () => Promise<T>,
  fallbackData?: T,
  options: FetchOptions = {},
): FetchResponse<T> => {
  const { isChanged, isAuthenticated } = useSelector(
    (state: RootState) => state.auth,
  );

  const { data, mutate, error, isLoading } = useSWR<T>(
    () => {
      if (options.waitForAuth && !isChanged) {
        return null;
      }

      return key;
    },
    fetcher,
    { fallbackData, suspense: options.suspense },
  );

  useEffect(() => {
    options.mutateForAuth && isAuthenticated && mutate();
  }, [isChanged]);

  return { data, mutate, error, isLoading };
};

export default useFetch;
