import { RootState } from "@/stores/rootReducer";
import { useRouter } from "next/router";
import { FC, PropsWithChildren, useEffect } from "react";
import { useSelector } from "react-redux";

export type LayoutProps = {
  requiredAuth?: boolean;
  show?: boolean;
} & PropsWithChildren;

const BaseLayout: FC<LayoutProps> = ({
  children,
  requiredAuth,
  show,
}): JSX.Element => {
  const { isAuthenticated, isChanged } = useSelector(
    (state: RootState) => state.auth,
  );
  const router = useRouter();

  useEffect(() => {
    if (!requiredAuth || !isChanged) {
      return;
    }

    if (!isAuthenticated) {
      router.push("/signin");

      return;
    }

    if (!show) {
      router.push("/");
    }
  }, [isChanged, router, show, requiredAuth, isAuthenticated]);

  if (requiredAuth && !isAuthenticated) {
    return null;
  }

  if (!show) {
    return null;
  }

  return <div className="relative">{children}</div>;
};

BaseLayout.defaultProps = {
  requiredAuth: false,
  show: true,
};

export default BaseLayout;
