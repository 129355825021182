import {
  NotificationActionEntityValue,
  NotificationActionType,
  NotificationResponse,
} from "@/web-client/api";
import Link from "next/link";
import { ReactNode, useMemo } from "react";
import { formatDistance, format } from "date-fns";
import { ja } from "date-fns/locale";

type UseNotificationReturn = {
  behavior: ReactNode;
  time: string;
};

const getPathFromActionEntity = (
  action_entity: NotificationActionEntityValue,
): string => {
  switch (action_entity.entity_type) {
    case "Post":
      return `/posts/${action_entity.id}`;

    case "Project":
      return `/projects/${action_entity.id}`;

    case "ProductVariation":
      return `/products/${action_entity.relational_id}?v_id=${action_entity.id}`;
  }
};

const useNotification = (
  notification: NotificationResponse,
  nowDate: Date,
): UseNotificationReturn => {
  const behavior = useMemo<ReactNode>(() => {
    switch (notification.action_type) {
      case NotificationActionType.LIKE: {
        const target = notification.action_entities[0];
        const targetHref = getPathFromActionEntity(target);
        const actionUser = notification.action_user;

        return (
          <>
            <Link href={`/users/${actionUser.id}`}>
              {actionUser.screen_name}
            </Link>
            さんが
            <Link href={targetHref}>{target.label}</Link>
            にLikeをつけました
          </>
        );
      }

      case NotificationActionType.FOLLOW: {
        const actionUser = notification.action_user;

        return (
          <>
            <Link href={`/users/${actionUser.id}`}>
              {actionUser.screen_name}
            </Link>
            さんがあなたをフォローしました
          </>
        );
      }

      case NotificationActionType.ADD_LIST: {
        const target = notification.action_entities[0];
        const list = notification.action_entities[1];
        const targetHref = getPathFromActionEntity(target);
        const actionUser = notification.action_user;

        return (
          <>
            <Link href={`/users/${actionUser.id}`}>
              {actionUser.screen_name}
            </Link>
            さんが
            <Link href={targetHref}>{target.label}</Link>を リスト『
            <Link href={`/lists/${list.id}`}>{list.label}</Link>』
            に追加しました
          </>
        );
      }

      case NotificationActionType.JOIN_TEAM: {
        const team = notification.action_entities[0];
        const actionUser = notification.action_user;

        return (
          <>
            <Link href={`/users/${actionUser.id}`}>
              {actionUser.screen_name}
            </Link>
            さんが
            <Link href={`/teams/${team.id}/projects`}>{team.label}</Link>
            に参加しました
          </>
        );
      }

      case NotificationActionType.APPROVE_TEAM: {
        const team = notification.action_entities[0];

        return (
          <>
            チーム「
            <Link href={`/teams/${team.id}/projects`}>{team.label}</Link>
            」は承認されました
          </>
        );
      }

      case NotificationActionType.REJECT_TEAM: {
        const team = notification.action_entities[0];

        return <>チーム「{team.label}」は承認されませんでした</>;
      }
    }
  }, [notification]);

  const time = useMemo(() => {
    const formatted = formatDistance(
      nowDate,
      Date.parse(notification.created_at),
      {
        locale: ja,
      },
    );

    if (formatted.indexOf("未満") !== -1) {
      return "たった今";
    } else if (
      formatted.indexOf("か月") !== -1 ||
      formatted.indexOf("年") !== -1
    ) {
      return format(Date.parse(notification.created_at), "yyyy年M月d日", {
        locale: ja,
      });
    }

    return formatted + "前";
  }, [notification, nowDate]);

  return {
    behavior,
    time,
  };
};
export default useNotification;
