import { NotificationResponse } from "@/web-client/api";
import useNotification from "@/features/notification/hooks/useNotification";
import Link from "next/link";
import { FC } from "react";
import Avatar, { AvatarSize } from "@/components/Avatar";
import useDevice from "@/hooks/useDevice";

type Props = {
  notification: NotificationResponse;
  nowDate: Date;
};

const NotificationItem: FC<Props> = ({
  notification,
  nowDate,
}): JSX.Element => {
  const { behavior, time } = useNotification(notification, nowDate);
  const { isSp } = useDevice();

  return (
    <article className="py-16 grid gap-12 grid-cols-[auto_1fr] items-start">
      <div className="flex items-center gap-12">
        {notification.action_user ? (
          <Link href={`/users/${notification.action_user_id}/posts`}>
            <Avatar
              src={notification.action_user.icon.urls.small}
              alt={notification.action_user.screen_name}
              size={isSp ? AvatarSize.Normal : AvatarSize.Large}
            />
          </Link>
        ) : (
          <Avatar
            src={"/imgs/icon-admin-notify.png"}
            alt={"管理者"}
            size={isSp ? AvatarSize.Normal : AvatarSize.Large}
          />
        )}
      </div>

      <div className="grid gap-4">
        <p className="[&>a]:font-bold [&>a]:text-link text-sm">{behavior}</p>

        <small className="text-secondary flex-shrink-0 text-xs">{time}</small>
      </div>
    </article>
  );
};
export default NotificationItem;
