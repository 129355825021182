import {
  FC,
  MouseEventHandler,
  ReactNode,
  useCallback,
  useMemo,
  useState,
  CSSProperties,
} from "react";
import { theme } from "tailwind.config";

type Props = {
  label?: ReactNode;
  icon?: ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  round?: BasicButtonRound;
  style: BasicButtonStyle;

  as?: "span";
  type?: "button" | "submit";
  disabled?: boolean;
  fontSize?: number;
};

export enum BasicButtonStyle {
  UniqueAction, // イエロー: 特徴的なアクションに利用するボタン
  Action, // 黒: 特徴的ではないが、行動を伴う大きめのアクション(API叩いたりする)に利用する
  Negative, // グレー: 行動を伴うがネガティブなアクション（非公開など）に利用する
  Edit, // 白: 編集や修正を伴う行動に利用するボタン
  Delete, // 赤: 削除を伴う行動に利用するボタン
  Valid, // グリーン: 正常な結果を表示し、行動を促進するためのボタン
}

const UniqueBaseStyle: CSSProperties = {
  color: theme.colors.gray[950],
  backgroundColor: theme.colors.yellow[500],
};

const ActionBaseStyle: CSSProperties = {
  color: theme.colors.white,
  backgroundColor: theme.colors.gray[950],
};

const NegativeBaseStyle: CSSProperties = {
  color: theme.colors.white,
  backgroundColor: theme.colors.gray[600],
};

const EditBaseStyle: CSSProperties = {
  color: theme.colors.gray[950],
  backgroundColor: theme.colors.white,
  border: "1px solid #ddd",
};

const DeleteBaseStyle: CSSProperties = {
  color: theme.colors.white,
  backgroundColor: theme.colors.red[500],
};

const ValidBaseStyle: CSSProperties = {
  color: theme.colors.white,
  backgroundColor: theme.colors.green[300],
};

export enum BasicButtonRound {
  Default,
  Full,
}

const BasicButton: FC<Props> = ({
  style,
  label,
  onClick,
  icon,
  round = BasicButtonRound.Default,
  as,
  type,
  fontSize = 14,
  disabled = false,
}): JSX.Element => {
  const [mouseOver, setMouseOver] = useState(false);

  const baseStyle = useMemo<CSSProperties>(() => {
    switch (style) {
      case BasicButtonStyle.UniqueAction:
        return mouseOver
          ? { ...UniqueBaseStyle, filter: "brightness(1.1)" }
          : UniqueBaseStyle;

      case BasicButtonStyle.Action:
        return mouseOver
          ? { ...ActionBaseStyle, filter: "brightness(1.1)" }
          : ActionBaseStyle;

      case BasicButtonStyle.Negative:
        return mouseOver
          ? { ...NegativeBaseStyle, filter: "brightness(1.1)" }
          : NegativeBaseStyle;

      case BasicButtonStyle.Edit:
        return mouseOver
          ? { ...EditBaseStyle, backgroundColor: theme.colors.gray[100] }
          : EditBaseStyle;

      case BasicButtonStyle.Delete:
        return DeleteBaseStyle;

      case BasicButtonStyle.Valid:
        return ValidBaseStyle;
    }
  }, [style, mouseOver]);

  const roundStyle = useMemo<CSSProperties>(() => {
    return {
      borderRadius: round === BasicButtonRound.Full ? 9999 : 4,
    };
  }, [round]);

  const className = useMemo<string>(
    () =>
      [
        "flex items-center justify-center",
        "font-bold leading-none",
        "transition duration-200",
      ].join(" "),
    [],
  );

  const mouseEnterHandler = useCallback(
    () => setMouseOver(true),
    [setMouseOver],
  );

  const mouseLeaveHandler = useCallback(
    () => setMouseOver(false),
    [setMouseOver],
  );

  const disabledStyle = useMemo<CSSProperties>(() => {
    return disabled
      ? {
          opacity: 0.24,
          cursor: "not-allowed",
        }
      : {};
  }, [disabled]);

  const paddingStyle = useMemo<CSSProperties>(() => {
    return {
      padding: "0.8em",
    };
  }, []);

  const iconStyle = useMemo<CSSProperties>(() => {
    if (icon && label) {
      return {
        marginRight: ".4em",
      };
    }

    return {};
  }, [icon, label]);

  return as !== "span" ? (
    <button
      className={className}
      style={{
        ...baseStyle,
        ...roundStyle,
        ...disabledStyle,
        ...paddingStyle,
        fontSize,
      }}
      onClick={onClick}
      onMouseEnter={mouseEnterHandler}
      onMouseLeave={mouseLeaveHandler}
      type={type}
      disabled={disabled}
    >
      {icon && <span style={iconStyle}>{icon}</span>}
      {label && <>{label}</>}
    </button>
  ) : (
    <span
      className={className}
      style={{
        ...baseStyle,
        ...roundStyle,
        ...disabledStyle,
        ...paddingStyle,
        fontSize,
      }}
      onMouseEnter={mouseEnterHandler}
      onMouseLeave={mouseLeaveHandler}
      aria-disabled={disabled}
    >
      {icon && <span style={iconStyle}>{icon}</span>}
      {label && <>{label}</>}
    </span>
  );
};
export default BasicButton;
