import client from "@/utils/api/client";
import { ProductKind, CategoryResponse } from "@/web-client/api";
import { useMemo } from "react";
import useSWR from "swr";

interface useSeparatedCategoriesResponse {
  interiorCategories: CategoryResponse[];
  materialCategories: CategoryResponse[];
  hasInteriorCategories: boolean;
  hasMaterialCategories: boolean;
}

interface UseCategoryResponse extends useSeparatedCategoriesResponse {
  categories: CategoryResponse[];
}

const useCategories = (): UseCategoryResponse => {
  const { data } = useSWR<CategoryResponse[]>(
    "/categories",
    async () => {
      const { data } = await client.categoriesGet();

      return data;
    },
    { revalidateOnFocus: false, revalidateOnReconnect: false },
  );

  const resp = useSeparatedCategories(data || []);

  return {
    categories: data || [],
    ...resp,
  };
};
export default useCategories;

export const useSeparatedCategories = (
  categories: CategoryResponse[],
): useSeparatedCategoriesResponse => {
  const interiorCategories = useMemo(() => {
    return categories.filter((v) => v.kind === ProductKind.INTERIOR);
  }, [categories]);

  const materialCategories = useMemo(() => {
    return categories.filter((v) => v.kind === ProductKind.BUILDING_MATERIAL);
  }, [categories]);

  return {
    interiorCategories,
    materialCategories,
    hasInteriorCategories: interiorCategories.length > 0,
    hasMaterialCategories: materialCategories.length > 0,
  };
};
