import {
  BuildingTypeResponse,
  CategoryResponse,
  MakerResponse,
  ProductKind,
  ProductVariationWithProductResponse,
  ProjectResponse,
  SuggestKeywordResponse,
  SuggestKeywordType,
  TeamResponse,
} from "@/web-client/api";
import useBuildingTypes from "@/hooks/useBuildingTypes";
import useCategories from "@/hooks/useCategories";
import Link from "next/link";
import { FC, useMemo } from "react";

interface Props {
  keyword: SuggestKeywordResponse;
}

export const SuggestItem: FC<{
  href: string;
  label: string;
  content: string;
}> = ({ href, label, content }) => {
  return (
    <Link
      href={href}
      className="flex flex-col p-12 gap-4 transition duration-200 hover:bg-gray-200"
    >
      <span className="block text-secondary text-xs">{label}</span>
      <span className="block text-sm font-bold">{content}</span>
    </Link>
  );
};

const SuggestMaker: FC<{ maker: MakerResponse }> = ({ maker }) => {
  return (
    <SuggestItem
      href={`/makers/${maker.id}`}
      label={"メーカー"}
      content={maker.name}
    />
  );
};

const SuggestCategory: FC<{ category: CategoryResponse }> = ({ category }) => {
  const { categories } = useCategories();

  const content = useMemo<string>(() => {
    return [
      ...category.ancestry.map(
        (id) => categories.find((c) => c.id === id)?.name,
      ),
      category.name,
    ].join(" > ");
  }, [category, categories]);

  const href = useMemo<string>(() => {
    return category.kind == ProductKind.INTERIOR
      ? `/interiors/product_variations/search?category_id=${category.id}`
      : `/building_materials/product_variations/search?category_id=${category.id}`;
  }, [category]);

  return <SuggestItem href={href} label={"カテゴリ"} content={content} />;
};

const SuggestBuildingType: FC<{
  buildingType: BuildingTypeResponse;
}> = ({ buildingType }) => {
  const buildingTypes = useBuildingTypes();
  const content = useMemo<string>(() => {
    return [
      ...buildingType.ancestry.map(
        (id) => buildingTypes.find((b) => b.id === id)?.name,
      ),
      buildingType.name,
    ].join(" > ");
  }, [buildingType, buildingTypes]);

  return (
    <SuggestItem
      href={`/architectures/projects/search?building_type_id=${buildingType.id}`}
      label={"ビルディングタイプ"}
      content={content}
    />
  );
};

const SuggestProject: FC<{ project: ProjectResponse }> = ({ project }) => {
  return (
    <SuggestItem
      href={`/projects/${project.id}`}
      label={"プロジェクト"}
      content={project.title}
    />
  );
};

const SuggestProductVariation: FC<{
  productVariation: ProductVariationWithProductResponse;
}> = ({ productVariation }) => {
  return (
    <SuggestItem
      href={`/products/${productVariation.product_id}?v_id=${productVariation.id}`}
      label={"プロダクト"}
      content={productVariation.full_name}
    />
  );
};

const SuggestTeam: FC<{
  team: TeamResponse;
}> = ({ team }) => {
  return (
    <SuggestItem
      href={`/teams/${team.id}/projects`}
      label={"チーム"}
      content={team.name}
    />
  );
};

const SuggestKeyword: FC<Props> = ({ keyword }) => {
  return (
    <div>
      {keyword.detailable_type == SuggestKeywordType.MAKER && (
        <SuggestMaker maker={keyword.detailable as MakerResponse} />
      )}
      {keyword.detailable_type == SuggestKeywordType.CATEGORY && (
        <SuggestCategory category={keyword.detailable as CategoryResponse} />
      )}
      {keyword.detailable_type == SuggestKeywordType.BUILDING_TYPE && (
        <SuggestBuildingType
          buildingType={keyword.detailable as BuildingTypeResponse}
        />
      )}
      {keyword.detailable_type == SuggestKeywordType.PROJECT && (
        <SuggestProject project={keyword.detailable as ProjectResponse} />
      )}
      {keyword.detailable_type == SuggestKeywordType.PRODUCT_VARIATION && (
        <SuggestProductVariation
          productVariation={
            keyword.detailable as ProductVariationWithProductResponse
          }
        />
      )}
      {keyword.detailable_type == SuggestKeywordType.TEAM && (
        <SuggestTeam team={keyword.detailable as TeamResponse} />
      )}
    </div>
  );
};

export default SuggestKeyword;
