import { FC, PropsWithChildren } from "react";

type Props = {
  active?: boolean;
};

const ActiveIconWrapper: FC<PropsWithChildren<Props>> = ({
  children,
  active = false,
}): JSX.Element => {
  return (
    <span className="relative block">
      {active && (
        <span className="absolute top-[-6px] left-1/2 transform -translate-x-1/2 w-[6px] h-[6px] rounded-full bg-yellow-500"></span>
      )}
      <span>{children}</span>
    </span>
  );
};
export default ActiveIconWrapper;
