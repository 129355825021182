import client from "@/utils/api/client";
import { BuildingTypeResponse } from "@/web-client/api";
import useSWR from "swr";

const useBuildingTypes = (): BuildingTypeResponse[] => {
  const { data } = useSWR<BuildingTypeResponse[]>(
    "/building_types",
    async () => {
      const { data } = await client.buildingTypesGet();

      return data;
    },
    { revalidateOnFocus: false, revalidateOnReconnect: false },
  );

  return data || [];
};

export default useBuildingTypes;
