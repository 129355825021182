import useSearchKeyword, { SearchKeywordType } from "@/hooks/useSearchKeyword";
import useSuggestKeywords from "@/hooks/useSuggestKeywords";
import dynamic from "next/dynamic";
import { FC, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import SuggestKeyword from "@/components/SuggestKeyword";

const DynamicSearchItems = dynamic(() => import("./SearchItems"));

interface Props {
  className?: string;
  placeholder: string;
  backgroundColor: string;
}

interface FormInput {
  keyword: string;
}

const SearchBar: FC<Props> = ({
  className,
  placeholder,
  backgroundColor,
}): JSX.Element => {
  const [focus, setFocus] = useState(false);
  const [hover, setHover] = useState(false);
  const { register, handleSubmit, watch } = useForm<FormInput>();
  const { search, keyword } = useSearchKeyword(SearchKeywordType.None);
  const suggestKeywords = useSuggestKeywords(watch("keyword"));

  const showHistory = useMemo<boolean>(() => {
    return (focus || hover) && suggestKeywords.length < 1;
  }, [focus, hover, suggestKeywords]);

  const showSuggest = useMemo<boolean>(() => {
    return (focus || hover) && suggestKeywords.length > 0;
  }, [focus, hover, suggestKeywords]);

  const onSubmit = (data: FormInput) => {
    if (data.keyword) {
      search(data.keyword);
    }
  };
  const { ref, onBlur, onChange, name } = register("keyword");

  return (
    <div className={["Searchbar text-primary text-left", className].join(" ")}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <input
          placeholder={placeholder}
          type="text"
          className="Searchbar__input"
          style={{
            backgroundColor,
            border: 0,
            borderRadius: 9999,
            padding: "8px 24px",
            fontSize: 16,
          }}
          defaultValue={keyword}
          autoComplete="off"
          ref={ref}
          name={name}
          onChange={onChange}
          onFocus={() => {
            setFocus(true);
          }}
          onBlur={(e) => {
            onBlur(e);
            setFocus(false);
          }}
        />
        <button type="submit" className="hidden" />
      </form>

      <div
        className="absolute w-full left-0"
        style={{ top: "calc(100% + 8px)" }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {showHistory && <DynamicSearchItems />}

        {showSuggest && (
          <ul className="BorderList shadow-under rounded-sm  overflow-hidden bg-white w-full max-h-[80vh] overflow-y-scroll">
            {suggestKeywords.map((suggestKeyword) => (
              <li key={suggestKeyword.id}>
                <SuggestKeyword
                  key={suggestKeyword.id}
                  keyword={suggestKeyword}
                />
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default SearchBar;
