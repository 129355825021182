import { addKeyword } from "@/stores/history";
import { useRouter } from "next/router";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

interface SearchKeywordResponse {
  search(keyword: string): void;
  link(keyword: string): string;
  keyword: string;
}

export enum SearchKeywordType {
  None,
  BuildingMaterial,
  Interior,
  Project,
  Post,
  Maker,
  List,
}

const SearchBasePath = "/search";
const SearchBuildingMaterialPath = `${SearchBasePath}/building_materials`;
const SearchInteriorPath = `${SearchBasePath}/interiors`;
const SearchProjectPath = `${SearchBasePath}/projects`;
const SearchPostPath = `${SearchBasePath}/posts`;
const SearchMakerPath = `${SearchBasePath}/makers`;
const SearchListPath = `${SearchBasePath}/lists`;

const useSearchKeyword = (
  type: SearchKeywordType = SearchKeywordType.Post,
): SearchKeywordResponse => {
  const [keyword, setKeyword] = useState("");
  const router = useRouter();
  const dispatch = useDispatch();
  const basePath = useMemo<string>(() => {
    if (router.route === SearchInteriorPath) return SearchInteriorPath;

    if (router.route === SearchBuildingMaterialPath)
      return SearchBuildingMaterialPath;

    if (router.route === SearchProjectPath) return SearchProjectPath;

    if (router.route === SearchPostPath) return SearchPostPath;

    if (router.route === SearchMakerPath) return SearchMakerPath;

    if (router.route === SearchListPath) return SearchListPath;

    switch (type) {
      case SearchKeywordType.None:
        return SearchBasePath;

      case SearchKeywordType.Interior:
        return SearchInteriorPath;

      case SearchKeywordType.BuildingMaterial:
        return SearchBuildingMaterialPath;

      case SearchKeywordType.Project:
        return SearchProjectPath;

      case SearchKeywordType.Post:
        return SearchPostPath;

      case SearchKeywordType.Maker:
        return SearchMakerPath;

      case SearchKeywordType.List:
        return SearchListPath;
    }
  }, [type, router]);

  const link = useCallback(
    (keyword: string) => {
      return `${basePath}?keyword=${encodeURIComponent(keyword)}`;
    },
    [basePath],
  );

  const search = useCallback(
    (keyword: string) => {
      dispatch(addKeyword(keyword));
      router.push(link(keyword));
    },
    [dispatch, router, link],
  );

  useEffect(() => {
    setKeyword(router.query.keyword as string);
  }, [router.query.keyword]);

  return {
    search,
    link,
    keyword,
  };
};

export default useSearchKeyword;
