import { RootState } from "@/stores/rootReducer";
import { FC, PropsWithChildren } from "react";
import { useSelector } from "react-redux";

const RenderIfNotAuth: FC<PropsWithChildren> = ({ children }): JSX.Element => {
  const { isAuthenticated, isChanged } = useSelector(
    (state: RootState) => state.auth,
  );

  return isChanged && !isAuthenticated && <>{children}</>;
};

export default RenderIfNotAuth;
