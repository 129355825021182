import { FC, useMemo } from "react";

interface Props {
  src: string;
  size: AvatarSize;
  alt: string;
}

export enum AvatarSize {
  Tiny = 20,
  Small = 24,
  Normal = 32,
  Large = 48,
  XLarge = 64,
  XXLarge = 80,
  Huge = 120,
  Giant = 160,
}

const Avatar: FC<Props> = ({
  src,
  size = AvatarSize.Normal,
  alt,
}): JSX.Element => {
  const style = useMemo(() => {
    return {
      width: size,
      height: size,
    };
  }, [size]);

  return (
    <img
      className="block rounded-full overflow-hidden object-cover"
      src={src}
      alt={alt}
      style={style}
    />
  );
};

export default Avatar;
