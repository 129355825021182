import { FC } from "react";
import Link from "next/link";
import { UserResponse } from "@/web-client/api";
import Avatar, { AvatarSize } from "@/components/Avatar";

interface Props {
  user: UserResponse;
  hasName?: boolean;
  hasLink?: boolean;
  size?: AvatarSize;
  layout?: UserIconLayout;
}

export enum UserIconLayout {
  Vertical = "vertical",
  Horizontal = "horizontal",
}

const UserIcon: FC<Props> = ({
  user,
  size,
  layout,
  hasName,
  hasLink,
}): JSX.Element => {
  const wrapperClass = ["UserIcon", size, layout];
  const container = (child: JSX.Element): JSX.Element => {
    if (!hasLink) {
      return child;
    }

    return (
      <Link href={`/users/${user.id}/posts`} prefetch={false}>
        {child}
      </Link>
    );
  };

  return (
    <div className={[...wrapperClass, "relative space-x-8"].join(" ")}>
      {container(
        <Avatar
          src={user.icon.urls.small}
          alt={user.screen_name}
          size={size}
        />,
      )}
      {hasName &&
        container(
          <p className="text-xs overflow-hidden whitespace-no-wrap">
            {user.screen_name}
          </p>,
        )}
    </div>
  );
};

UserIcon.defaultProps = {
  hasName: false,
  hasLink: true,
  size: AvatarSize.Normal,
  layout: UserIconLayout.Horizontal,
};

export default UserIcon;
