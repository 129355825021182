import { fetchMe } from "@/stores/auth";
import { useAppDispatch } from "@/stores/index";
import { RootState } from "@/stores/rootReducer";
import client from "@/utils/api/client";
import { useCallback } from "react";
import { useSelector } from "react-redux";

type UseHasReadNotificationReturn = {
  hasRead: VoidFunction;
};

const useHasReadNotification = (): UseHasReadNotificationReturn => {
  const appDispatch = useAppDispatch();
  const { user } = useSelector((state: RootState) => state.auth);

  const hasRead = useCallback(async () => {
    if (user) {
      // 未読の通知がなければ叩かなくても良い
      if (!user.has_unread_notification) return;

      await client.notificationsReadPost();

      // has_unread_notificationを更新するためにたたく
      await appDispatch(fetchMe());
    }
  }, [user, appDispatch]);

  return {
    hasRead,
  };
};
export default useHasReadNotification;
